export const menu = [
    {
        label: "首页",
        src: "/",
        children: [],
    },
    {
        label: "中心简介",
        src: "/introduction",
        children: [
            // {
            //     label: "中心简介",
            //     src: "/base/introduction",
            //     name: "introduction",
            //     show: false
            // }
            // {
            //     label: "简介详情",
            //     src: "/base/introduction",
            //     name: "introduction",
            //     show: true
            // },
        ],
    },
    {
        label: "视频中心",
        src: "/videos",
        children: [
            {
                label: "视频中心",
                src: "/videos",
                name: "videos",
                show: false,
            },
            {
                label: "搜索结果",
                src: "/videos/search",
                name: "search-result",
                show: false,
            },
            {
                label: "更多视频",
                src: "/videos/videoCourse",
                name: "more-videoCourse",
                show: false,
            },
            {
                label: "更多合集",
                src: "/videos/coCourse",
                name: "more-coCourse",
                show: false,
            },
            {
                label: "视频",
                src: "/videos/:id",
                name: "video-detail",
                show: false,
            },
        ],
    },
    {
        label: "信息发布",
        src: "/base/news",
        children: [
            {
                label: "新闻",
                src: "/base/news",
                name: "news1",
                show: true,
            },
            {
                //轮播
                label: "E-Talk",
                src: "/base/eTalk",
                name: "eTalk-index",
                show: true,
            },
            {
                label: "特色课程",
                src: "/base/courses",
                name: "courses",
                show: true,
            },
            {
                //轮播
                label: "活动竞赛",
                src: "/base/information/competition-activities",
                name: "competition-activities-index",
                show: true,
            },
            {
                //轮播
                label: "科创课题",
                src: "/base/info/project",
                name: "project-index",
                show: true,
            },
            {
                label: "科创课题",
                src: "/base/info/project",
                name: "science",
                show: false,
            },
            {
                label: "E-Talk",
                src: "/base/eTalk",
                name: "eTalk",
                show: false,
            },
            {
                label: "活动竞赛",
                src: "/base/information/competition-activities",
                name: "competition-activities",
                show: false,
            },
            {
                label: "活动竞赛详情",
                src: "/base/information/competition-activities/detail/:id",
                name: "competition-activities-detail",
                show: false,
            },
            {
                label: "科创课题详情",
                src: "/base/info/project",
                name: "science-detail",
                show: false,
            },
            {
                label: "新闻详情",
                src: "/base/news/detail/:id",
                name: "news-detail1",
                show: false,
            },
            {
                label: "E-Talk详情",
                src: "/base/eTalk/detail",
                name: "eTalk-detail",
                show: false,
            },
            {
                label: "E-Talk预约",
                src: "/base/eTalk/order/detail",
                name: "eTalk-order",
                show: false,
            },
            {
                label: "特色课程详情",
                src: "courses/detail/:id",
                name: "courses-detail",
                show: false,
            },
            {
                label: "TEDxSJTU",
                src: "/base/ted-x",
                name: "Ted-x",
                show: true,
            },
            {
                label: "党建动态",
                src: "/base/govnews",
                name: "govnews",
                show: true,
            },
            {
                label: "党建动态", // 详情
                src: "/base/govnews/detail/:id",
                name: "govnews-detail1",
                show: false,
            },
        ],
    },
    {
        label: "协同创新",
        src: "/base/innovation/ex-lab",
        children: [
            {
                label: "Ex-Lab",
                src: "/base/innovation/ex-lab",
                name: "ex-lab",
                show: true,
            },
            {
                label: "E-Club",
                src: "/base/innovation/e-club",
                name: "e-club",
                show: true,
            },
            {
                label: "E-Club详情页",
                src: "/base/innovation/e-club",
                name: "e-club-detail",
                show: false,
            },
        ],
    },
    {
        label: "校企合作",
        src: "/base/cooperation/news",
        children: [
            {
                label: "共建资讯",
                src: "/base/cooperation/news",
                name: "news",
                show: true,
            },
            {
                label: "合作指南",
                src: "/base/cooperation/guide",
                name: "guide",
                show: true,
            },
            {
                label: "申请入口",
                name: "apply",
                src: "/base/cooperation/apply",
                show: true,
            },
            {
                label: "共建资讯详情页",
                src: "/base/cooperation/news/detail/id",
                name: "news-detail",
                show: false,
            },
        ],
    },
    {
        label: "成果展示",
        src: "/base/achievement/project",
        children: [
            {
                label: "项目",
                name: "project",
                src: "/base/achievement/project",
                show: true,
            },
            {
                label: "竞赛",
                name: "competition",
                src: "/base/achievement/competition",
                show: true,
            },
            {
                name: "treatise",
                label: "论文",
                src: "/base/achievement/treatise",
                show: true,
            },
            // {
            //     name: "patent",
            //     label: "专利",
            //     src: "/base/achievement/patent",
            //     show: true
            // },
            // {
            //     label: "其他",
            //     src: "/base/achievement/other",
            //     show: true
            // },
            {
                label: "项目详情",
                name: "project-detail",
                src: "/base/achievement/project/detail/:id",
                show: false,
            },
            {
                label: "竞赛详情",
                name: "competition-detail",
                src: "/base/achievement/competition/detail/:id",
                show: false,
            },
        ],
    },
    {
        label: "服务大厅",
        name: "device",
        src: "/base/service/device",
        children: [
            {
                label: "设备预约",
                name: "device",
                show: true,
                src: "/base/service/device",
            },
            {
                label: "场地预约",
                show: true,
                name: "reservation",
                src: "/base/service/reservation",
            },
            {
                label: "文档下载",
                src: "/base/service/download",
                name: "download",
                show: true,
            },
            {
                label: "办事指南",
                src: "/base/service/guidence",
                name: "guidence",
                show: true,
            },
            {
                label: "互动天地",
                src: "/base/service/publish",
                name: "publish",
                show: true,
            },
            // {
            //     label: "互动天地-详情",
            //     src: "/base/service/publish/detail",
            //     name: "publish-detail",
            //     show: false
            // },
            {
                label: "设备详情",
                name: "device-detail",
                show: false,
                src: "/base/service/device/detail",
            },
            {
                label: "预约须知",
                name: "device-notice",
                show: false,
                src: "/base/service/device/notice",
            },
            {
                label: "办事指南详情",
                src: "/base/service/guidence/detail/id",
                name: "guidence-detail",
                show: false,
            },
        ],
    },
    // {
    //     label: "党政",
    //     src: "/gov/news",
    //     children: [
    //         {
    //             label: "动态",
    //             src: "/gov/news",
    //             name: "govnews",
    //             show: true,
    //         },
    //         {
    //             label: "党政动态",
    //             src: "/gov/news/:id",
    //             name: "govnews-detail1",
    //             show: false,
    //         },
    //     ],
    // },
];
