<template>
    <div class="footer">
        <div class="footer_wrap">
            <div class="footer_left">
                <div
                    v-for="(item, index) in footerList"
                    class="fotter-item"
                    :key="index"
                >
                    <img class="footer_box" :src="item.src" />
                </div>
            </div>
            <div class="footer_right">
                <div class="footer_right_top">
                    <img
                        class="footer_logo"
                        src="http://cdn.sjtuair.com/0408c030-0200-11eb-8ee9-2557a0f6083a.png"
                        alt
                    />
                    <div class="footer_info">
                        <div class="footer_content_title">
                            {{ "教学发展与学生创新中心" | language }}
                        </div>
                        <div class="footer_content_name">
                            {{
                                "上海交通大学电子信息与电气工程学院" | language
                            }}
                        </div>
                    </div>
                </div>
                <div class="footer_content">
                    <div
                        class="footer_content_inner"
                        :class="[isCN ? 'fix-cn-inner' : '']"
                    >
                        <div
                            class="footer_line_content"
                            style="margin-top: 10px"
                        >
                            <Icon type="ios-call-outline" />
                            <span class="footer_inner">021-34207422</span>
                        </div>
                        <div class="footer_line_content">
                            <Icon type="ios-mail-outline" />
                            <span class="footer_inner"
                                >evalley@sjtu.edu.cn</span
                            >
                        </div>
                        <div class="footer_line_content">
                            <img class="qq-img" src="@/assets/img/qq.svg" alt />
                            <span class="footer_inner">1626367365</span>
                        </div>
                        <div class="footer_line_content footer-border-bottom">
                            <Icon type="ios-navigate-outline" />
                            <span class="footer_inner">{{
                                "上海交通大学电信群楼四号楼" | language
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="footer_img_wrap">
                    <Poptip trigger="hover" title="">
                        <img
                            src="http://cdn.sjtuair.com/75326700-c814-11ea-8b3e-01fbc3f539e7.jpeg"
                            slot="content"
                            class="wechat-img"
                            alt=""
                        />
                        <img src="@/assets/img/wechat.svg" alt />
                    </Poptip>

                    <img src="@/assets/img/instagram.svg" alt />
                    <img src="@/assets/img/bilibili.svg" alt />
                </div>
                <a
                    href="http://www.beian.miit.gov.cn"
                    target="_blank"
                    rel="nofollow"
                    class="slide-ct"
                >
                    沪交ICP备20200171
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            footerList: [],
        };
    },
    mounted() {
        this.fetchFooter();
    },
    computed: {
        localLanguage() {
            return this.$store.state.language;
        },
        isCN() {
            return this.localLanguage !== "en";
        },
    },
    methods: {
        // 获取footer logo墙
        async fetchFooter() {
            let res = await this.$http.get("/home/footer");
            this.footerList = res.logos;
        },
    },
};
</script>

<style lang="less" scoped>
.footer {
    display: flex;
    justify-content: center;
    height: 380px;
    width: 100%;
    .footer_wrap {
        width: 1200px;
        display: flex;
        height: 100%;
        padding: 44px 0 66px 0;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #00305b;
        .footer_left {
            height: 100%;
            width: 612px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .footer_box {
                display: flex;
                width: 184px;
                height: 70px;
                object-fit: contain;
                object-position: center;
                margin: 2px;
            }
        }
        .footer_right {
            text-align: left;
            padding-left: 60px;
            display: flex;
            justify-content: center;
            flex: 1;
            height: 100%;
            flex-direction: column;
            .footer_right_top {
                display: flex;
                align-items: center;
                padding-bottom: 12px;
                justify-content: center;
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            }
            .footer_logo {
                flex-shrink: 0;
                width: 55px;
                margin-right: 30px;
            }
            .footer_info {
                // color: #fff;
                // opacity: 0.3;
                color: #0c2433;
                .footer_content_title {
                    font-size: 24px;
                    letter-spacing: 0;
                    line-height: 24px;
                }
                .footer_content_name {
                    margin-top: 6px;
                    font-size: 14px;
                    line-height: 16px;
                }
            }
            .footer_content {
                display: flex;
                align-items: center;
                justify-content: center;
                // color: rgba(255, 255, 255, 0.3);
                color: #0c2433;
                padding-bottom: 12px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                .fix-cn-inner {
                    padding-left: 10px;
                }
                .footer_line_content {
                    padding: 5px 0;
                    .footer_inner {
                        margin-left: 8px;
                    }
                    &:hover {
                        opacity: 1;
                    }
                    .qq-img {
                        width: 14px;
                        height: 14px;
                        opacity: 0.3;
                        vertical-align: sub;
                        user-select: none;
                    }
                    .ivu-icon {
                        font-size: 16px;
                        color: #fff;
                        opacity: 0.3;
                        vertical-align: sub;
                        user-select: none;
                    }
                }
            }
        }
        .footer_img_wrap {
            display: flex;
            padding: 12px 0 0 0;
            justify-content: center;
            align-items: center;
            img:not(.wechat-img) {
                margin-left: 30px;
                cursor: pointer;
                width: 30px;
                height: 30px;
                object-fit: cover;
                opacity: 0.5;
                &:first-child {
                    margin-left: 0;
                }
                &:hover {
                    opacity: 1;
                }
            }
            .wechat-img {
                width: 200px;
                height: 200px;
            }
        }
        .slide-ct {
            text-align: center;
            margin-top: 20px;
            color: #0c2433;
        }
    }
}
@media screen and (max-width: 960px) {
    .footer {
        height: auto;
        padding: 0 8vw;
        .footer_wrap {
            width: 100%;
            flex-direction: column;
            padding: 4vw 0;
            .footer_left {
                width: 100%;
                .fotter-item {
                    width: 48%;
                    margin-bottom: 8px;
                    img {
                        width: 100%;
                    }
                }
            }
            .footer_right {
                padding-left: 0;
                margin-top: 30px;
                .footer_info {
                    .footer_content_title {
                        font-size: 20px;
                        line-height: 1.5;
                    }
                    .footer_content_name {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
</style>
