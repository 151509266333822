<template>
    <div class="header-container">
        <!-- 左侧logo -->
        <div
            class="header-container__title flex-container"
            :class="[isEn ? 'fix-en-left-header' : '']"
        >
            <img
                class="header-container__title__logo"
                src="http://cdn.sjtuair.com/d1443b60-2e60-11eb-8ef1-c1ca94551e62.png"
                alt
                @click="goTo('/')"
            />
            <div class="header-container__title__content" @click="goTo('/')">
                <div class="header-container__title__content__upper">
                    {{ "教学发展与学生创新中心" | language }}
                </div>
                <div class="header-container__title__content__lower">
                    {{ "上海交通大学电子信息与电气工程学院" | language }}
                </div>
            </div>
            <div class="fix-flex"></div>
        </div>
        <!-- 右侧导航栏 -->
        <div class="header-container__menu">
            <div class="header-container__menu__upper">
                <div
                    class="icon-container click-op"
                    @click="goTo('https://vr.justeasy.cn/view/9754689.htm')"
                >
                    <Icon type="ios-navigate-outline" class="header-icon" />
                    <span class="name">{{ "电子地图" | language }}</span>
                </div>
                <div
                    class="icon-container click-op"
                    @click="goTo('/common/search')"
                >
                    <Icon type="ios-search" class="header-icon" />
                    <span class="name">{{ "搜索" | language }}</span>
                </div>
                <div
                    class="icon-container click-op"
                    @click="beforeChangeLanguage"
                >
                    <Icon type="ios-globe-outline" class="header-icon" />
                    <transition
                        mode="out-in"
                        :duration="700"
                        enter-active-class="animated bounceIn "
                        leave-active-class="animated bounceOut "
                        appear
                    >
                        <div class="name" v-if="localLanguage === 'en'">
                            中文
                        </div>
                        <div class="name" v-else>English</div>
                    </transition>
                </div>
                <div class="icon-container login-btn">
                    <Icon type="ios-contact-outline" class="header-icon" />
                    <Poptip
                        placement="bottom-end"
                        trigger="click"
                        class="blue"
                        v-if="!(admin && Object.keys(admin).length)"
                    >
                        <span class="name">{{ "登录" | language }}</span>
                        <div class="api login-container" slot="content">
                            <div class="login-item click-op" @click="goToJacc">
                                <img
                                    src="http://cdn.sjtuair.com/d3508ca0-6990-11ea-bd25-4953e0adea16.png"
                                    alt
                                />
                                <span class="login-type">{{
                                    "Jaccount登录" | language
                                }}</span>
                            </div>
                            <div
                                class="login-item click-op"
                                @click="goTo('/common/login')"
                            >
                                <img
                                    src="http://cdn.sjtuair.com/d3514ff0-6990-11ea-bd25-4953e0adea16.png"
                                    alt
                                />
                                <span class="login-type">{{
                                    "访客登录" | language
                                }}</span>
                            </div>
                            <!-- <div class="login-item mr-t2 click-op">
								<img
									src="http://cdn.sjtuair.com/aa4f4ac0-3368-11ea-abd0-69bd09a4c42b.png"
									alt=""
								/>
								<span class="login-type">{{ '访客' | language }}</span>
							</div>
							<div class="login-item mr-t2 click-op">
								<img
									src="http://cdn.sjtuair.com/aa4f4ac0-3368-11ea-abd0-69bd09a4c42b.png"
									alt=""
								/>
								<span class="login-type">{{ '校友' | language }}</span>
							</div> -->
                        </div>
                    </Poptip>
                    <Dropdown
                        class="mr-lh"
                        trigger="hover"
                        v-else
                        @on-visible-change="ifChange"
                        @on-click="dropdownClick"
                    >
                        <a href="javascript:void(0)" class="user-link">
                            {{ "用户" | language }}
                            <Icon
                                type="ios-arrow-down"
                                class="down"
                                :class="{ 'icon-active': iconShow }"
                            ></Icon>
                        </a>
                        <DropdownMenu slot="list">
                            <DropdownItem name="setting">
                                {{ "个人中心" | language }}</DropdownItem
                            >
                            <DropdownItem name="notice">
                                {{ "消息" | language }}</DropdownItem
                            >
                            <DropdownItem name="logout">
                                {{ "退出登录" | language }}</DropdownItem
                            >
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>

            <div class="header-container__menu__list">
                <div
                    class="header-container__menu__list__item"
                    v-for="(item, index) in menu"
                    :key="index"
                >
                    <div
                        class="header-container__menu__list__item__title"
                        :class="[isEn ? 'fix-en-title' : '']"
                        @click="routePush(item, index)"
                    >
                        {{ item.label | language }}
                    </div>
                    <div class="header-container__menu__list__item__content">
                        <template v-for="(y, i) in item.children">
                            <div
                                v-if="y.show"
                                @click="routeTo(y)"
                                :key="i"
                                class="header-container__menu__list__item__content__link"
                            >
                                {{ y.label | rePlacelanguage }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <Icon
            @click.native.stop="showMenu = true"
            class="menu-icon"
            type="md-menu"
        />
        <Drawer
            :title="' 教学发展与学生创新中心' | language"
            v-model="showMenu"
        >
            <Collapse v-model="showIndex" accordion>
                <Panel
                    :name="mn.src"
                    v-for="(mn, ind) in menu"
                    :key="'mn' + ind"
                >
                    {{ mn.label }}
                    <List header="" slot="content" footer="" border>
                        <ListItem
                            @click.native="routeTo(mn)"
                            v-if="mn.children && mn.children.length === 0"
                        >
                            {{ mn.label }}
                            <Icon type="ios-arrow-forward" slot="extra" />
                        </ListItem>
                        <template v-else>
                            <div
                                v-for="(chd, ind2) in mn.children"
                                :key="'chd' + ind2"
                            >
                                <ListItem
                                    v-if="chd.show"
                                    @click.native="routeTo(chd)"
                                >
                                    {{ chd.label }}
                                    <Icon
                                        type="ios-arrow-forward"
                                        slot="extra"
                                    />
                                </ListItem>
                            </div>
                        </template>
                    </List>
                </Panel>
            </Collapse>
        </Drawer>
    </div>
</template>

<script>
import { menu } from "../../assets/util/menu";
import { languageTranslation } from "../../assets/util/language";
import { httpURL } from "../../assets/util/http";
export default {
    data() {
        return {
            menu: [],
            show: null,
            iconShow: false,
            showMenu: false,
            showIndex: "",
        };
    },
    filters: {
        rePlacelanguage(val) {
            if (!val) return val;
            // 先翻译，再转url编码，再替换&nbsp;空格再转回编码
            return decodeURI(
                encodeURI(languageTranslation(val)).replace(/%C2%A0/g, "%20")
            );
        },
    },
    computed: {
        localLanguage() {
            return this.$store.state.language;
        },
        isEn() {
            return this.localLanguage === "en";
        },
    },
    created() {
        this.menu = menu;
        if (window && window.innerWidth < 961) {
            if (!this.admin) return;
            if (this.menu.length !== 0) {
                let lastItem = this.menu.slice(-1)[0];
                if (lastItem.label === "个人中心") return;
            }
            let item = {
                label: "个人中心",
                src: "/common/setting",
                children: [
                    {
                        label: "基本设置",
                        src: "/common/setting",
                        name: "setting",
                        show: true,
                    },
                    {
                        label: "消息通知",
                        src: "/common/notice",
                        name: "notice",
                        show: true,
                    },
                    {
                        label: "设备预约",
                        src: "/common/reserve/device",
                        name: "reserveDevice",
                        show: true,
                    },
                    {
                        label: "场地预约",
                        src: "/common/service/device",
                        name: "reserveService",
                        show: true,
                    },
                ],
            };
            this.menu.push(item);
        }
    },
    methods: {
        ifChange(visible) {
            this.iconShow = visible;
        },
        dropdownClick(name) {
            if (name == "setting") {
                return this.goTo("/common/setting");
            } else if (name == "notice") {
                return this.goTo("/common/notice");
            } else {
                this.$router.push("/");
                this.$Message.success(languageTranslation("退出登录成功！"));
                this.$store.dispatch("logOut");
            }
        },
        goTo(url) {
            if (url.indexOf("http") > -1) {
                return window.open(url);
            }
            this.$router.push(url);
        },
        goToJacc() {
            window.open(`${httpURL}/user/auth`);
        },
        beforeChangeLanguage() {
            if (this.localLanguage === "zh") {
                this.changeLanguage("en");
            } else {
                this.changeLanguage("zh");
            }
        },
        changeLanguage(val) {
            this.$Spin.show();
            setTimeout(() => {
                this.$router.go(0);
                this.$store.commit("set_ele_language_en", val);
                this.$Spin.hide();
            }, 2000);
        },
        routeTo(item) {
            this.$router.push(item.src);
            this.showMenu = false;
        },
        routePush(item, index) {
            this.$router.push(item.src);
            // if (index > 2) return;
            // else {
            //     this.$router.push(item.src);
            // }
        },
    },
};
</script>

<style>
.login-btn .ivu-poptip-popper[x-placement^="bottom"] .ivu-poptip-arrow:after {
    border-bottom-color: #0091ff;
}
.login-btn .ivu-poptip-body {
    background-color: #0091ff;
    padding: 0;
}
a:focus {
    text-decoration: none;
}
</style>
<style lang="less" scoped>
/deep/.ivu-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-container {
    background-color: #fff;
    color: #2c3e50;
    height: 116px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-icon {
        font-size: 16px;
    }
    .header-container__title {
        cursor: pointer;
    }
}
.gov-gb {
    background-color: #bc0000;
}
// 左侧标题 ///////////////////////
.header-container__title__logo {
    margin-left: 43px;
    width: 64px;
    flex-shrink: 0;
}
.header-container__title__content {
    margin-left: 14px;
}
.header-container__title__content__upper {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}
.header-container__title__content__lower {
    font-size: 16px;
    line-height: 24px;
    margin-top: 6px;
    text-align: left;
}
.fix-en-left-header {
    width: 33.33vw;
    .header-container__title__content__upper {
        font-size: 20px;
        line-height: 20px;
    }
    .header-container__title__content__lower {
        line-height: 20px;
    }
}
// 导航栏 ///////////////////////
.header-container__menu {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: 40px;
}
.header-container__menu__upper {
    margin-right: -10px;
    display: flex;
    margin-top: 8px;
    font-size: 14px;
    .icon-container {
        padding: 10px 10px 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-left: 10px;
        .down {
            transition: all linear 0.3s;
        }
        .icon-active {
            transform: rotate(-180deg);
        }

        icon {
            width: 16px;
            height: 16px;
            margin-right: 3px;
        }
        .name {
            margin-left: 5px;
        }
        .login-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            .login-item {
                padding: 10px 20px;
                height: 60px;
                display: flex;
                align-items: center;
                > img {
                    width: 25px;
                    height: 25px;
                }
                .login-type {
                    margin-left: 15px;
                    text-align: left;
                    width: 120px;
                    color: #fff;
                    font-size: 18px;
                    font-weight: 600;
                }
                &:hover {
                    background-color: #00305b;
                    cursor: pointer;
                    user-select: none;
                }
            }
        }
    }
    .login-btn {
        user-select: none;
        &:hover {
            cursor: pointer;
        }
    }
}
.header-container__menu__list {
    display: flex;
    align-items: flex-start;
    z-index: 111;
}
.header-container__menu__list__item {
    text-align: left;
    user-select: none;
    position: relative;
    &:hover {
        cursor: pointer;
    }
    &:hover .header-container__menu__list__item__title {
        background-color: #0c2433;
        color: #fff;
    }
    &:hover .header-container__menu__list__item__content {
        display: block;
    }
}
.header-container__menu__list__item:last-child {
    margin-right: -20px;
}
.header-container__menu__list__item__title {
    font-size: 18px;
    padding: 15px 10px;
    &:hover {
        background-color: #0c2433;
        color: #fff;
    }
}
.header-container__menu__list__item__content {
    display: none;
    font-size: 16px;
    background-color: #0c2433;
    margin-top: -2px;
    position: absolute;
    top: 53px;
    left: 0px;
    right: 0px;
    cursor: pointer;
    color: #fff;
}
.show {
    visibility: visible;
}
.header-container__menu__list__item__content__link {
    padding: 10px 10px;
    font-size: 14px;
    text-align: center;

    &:hover {
        background-color: #0c2433;
        color: #fff;
    }
}
.fix-en-title {
    font-size: 14px;
}
.menu-icon {
    display: none;
    font-size: 32px;
    color: #ffffff;
    margin-right: 21px;
}
@media screen and (max-width: 960px) {
    .header-container {
        height: 99px;
        // position: fixed;
        // top: 0;
        // width: 100%;
        // left: 0;
        // z-index: 9999;
    }
    .header-container__menu {
        display: none;
    }
    .header-container__title__logo {
        margin-left: 21px;
        width: 52px;
    }
    .header-container__title__content {
        margin-left: 12px;
    }
    .header-container__title__content__upper {
        font-size: 20px;
        line-height: 20px;
    }
    .header-container__title__content__lower {
        font-size: 12px;
        line-height: 20px;
    }
    .menu-icon {
        display: block;
    }
}
.user-link {
    color: #00305b;
}
</style>
